import React from 'react'
import Login from './Login'

function Admin() {
  return (
    <div>
        <Login/>
    </div>
  )
}

export default Admin
