import React from 'react';


function Features() {
  return (
    <div className="bg-gray-100 min-h-screen py-12 p-4">
      <div className="container mx-auto">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
            <div>
        <h1 className="text-3xl font-bold mb-8">Why Choose Us</h1>
        <p className="text-lg mb-12">Provide you a complete healing Solution</p>
        <p>Lorem ipsum dolor sit amet consectetur, adipisicing elit. Veniam eos aliquid minus dolore obcaecati, molestias pariatur blanditiis assumenda dolorum, voluptate maiores non placeat dicta aperiam saepe optio repudiandae et enim! Lorem ipsum dolor sit, amet consectetur adipisicing elit. Ipsa nostrum, facere, magni autem modi impedit labore facilis excepturi ullam libero reiciendis pariatur voluptas vel ad explicabo aperiam? Officia, magnam quis.</p>
            </div>
            <div className='img-feat'>
            </div>
        </div>
      </div>
    </div>
  );
}

export default Features;
